// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Vuetify
@import  '~vuetify/dist/vuetify.min.css' ;

// Material Desing Icons
@import '~@mdi/font/scss/materialdesignicons' ;

body{
  font-size: 1.2em !important;
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
} 

.normal-break {
  word-wrap: normal !important
}